import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import HeadWithQuery from '../head/head';

const Layout = ({ children }) => (
  <Container style={{ width: '100vw' }}>
    <HeadWithQuery />
    <Grid style={{ width: '100vw' }}>{children}</Grid>
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

export default Layout;
